import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Button, Spinner } from "react-bootstrap";
import { countUserEvent } from "../utils/utlis";

const ForgetPassword = ({ user, setData, setUser, data, setForget }) => {
  const auth = getAuth();
  const [sucess, setSucess] = React.useState(false);
  const [loading, setLoading] = useState(false);
  console.log("loading", loading);
  const email = user?.email;
  const resetPassword = () => {
    setLoading(true);
    sendPasswordResetEmail(auth, email)
      .then((res) => {
        console.log(res);
        setSucess(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="accounts-body">
      <img className="accounts-logo" src={require("../images/logo/logo.png")} />
      <div className="create-account-card">
        <h1 className="mb-0">Forget your password?</h1>
        <div className="terms-privacy mb-4">
          We'll email instructions to <br /> {user?.email} on how to reset it.
        </div>
        <div className="form-card">
          <div className="row">
            <div className="col-lg-12">
              <div className="user-card">
                <div className="profile">
                  <img
                    className="user"
                    src={require("../images/AVATARS/Flare.png")}
                  />
                  <span>
                    {user?.displayName ??
                      (user?.given_name
                        ? user?.given_name + " " + user?.family_name
                        : user?.email)}
                    {user?.given_name || user?.displayName ? (
                      <>
                        <br />
                        {/* <span className="fs-6 text-muted">{user?.email}</span> */}
                      </>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                <Button
                  variant="light"
                  className="edit"
                  onClick={() => {
                    setData({ ...data, password: "" });
                    setForget(false);
                  }}
                >
                  <i className="fa-regular fa-pen"></i>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <button className="btn btn-primary mt-4" onClick={() => {
          resetPassword()
          countUserEvent('Forgot_Password', { eventName: 'Forgot_Password' });
        }}>
          {loading ? <Spinner /> : "Reset password"}
        </button>

        <div className="help-center mt-5">
          <a href="https://docs.ilmiya.com">Help</a>
          <span>|</span>
          <a href="https://ilmiya.com/legal/privacy-policy">Privacy</a>
          <span>|</span>
          <a href="https://ilmiya.com/legal/terms-of-service">Terms</a>
        </div>
        {sucess && (
          <div className="right-pass-card">
            <i className="fa-solid fa-circle-check"></i>
            <span>A link to reset your password has been emailed to you.</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgetPassword;
