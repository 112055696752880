import React from 'react'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { countUserEvent } from '../utils/utlis';

export default function Lxpaccounts() {
    return (
        <div>

            {/* create account */}
            <div className='accounts-body'>
                <img className='accounts-logo' src={require('../images/logo/logo.png')} />
                <div className='create-account-card'>
                    <h1>Create an ilmiya account</h1>
                    <a href='#' className='email-signup-btn'>
                        <i className="fa-regular fa-envelope-open"></i>
                        <span onClick={()=>countUserEvent('Sign_Up' , {eventName : "Sign_Up_With_Email"})} >
                            Sign up with email
                        </span>
                    </a>
                    <div className='devider'>
                        <div className='linee'></div>
                        <span onClick={()=>countUserEvent('Sign_Up_Contiue_With' , {eventName : "Sign_Up_Contiue_With"})} >Or continue with</span>
                    </div>
                    <div className='other-options'>
                        <div className='row'>
                            <div className='col-4'>
                                <div className='option-btn'>
                                    <img src={require('../images/logo/Google.png')} />
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='option-btn'>
                                    <img src={require('../images/logo/apple.png')} />
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='option-btn'>
                                    <img src={require('../images/logo/Microsoft.png')} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div onClick={()=>countUserEvent('Sign_Up_Contiue_With' , {eventName : "Sign_Up_Contiue_With"})} className='already-have-account'>
                        Already have an ilmiya account? <a href=''> Login <i className="fa-regular fa-arrow-right"></i></a>
                    </div>
                    <div className='terms-privacy'>
                        By creating an account you agree to the <a href=''>Terms of Service</a> and <a href=''>Privacy Policy</a>.
                    </div>
                    <div className='help-center'>
                        <a href='https://docs.ilmiya.com'>Help</a>
                        <span>|</span>
                        <a href='https://ilmiya.com/legal/privacy-policy'>Privacy</a>
                        <span>|</span>
                        <a href='https://ilmiya.com/legal/terms-of-service'>Terms</a>
                    </div>
                </div>
            </div>
            {/* create account */}

            {/* create account form */}
            <div className='accounts-body'>
                <img className='accounts-logo' src={require('../images/logo/logo.png')} />
                <div className='create-account-card'>
                    <h1>Create an ilmiya account</h1>
                    <div className='form-card'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='input-group'>
                                    <input type="text" placeholder='Email' className='form-control' />
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='input-group'>
                                    <input type="text" placeholder='First name' className='form-control' />
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='input-group'>
                                    <input type="text" placeholder='Last name' className='form-control' />
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className='input-group'>
                                    <input type="password" placeholder='New password' className='form-control' />
                                    <i className="fa-light fa-eye"></i>
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className='input-group'>
                                    <input type="password" placeholder='Confirm password' className='form-control' />
                                    <i className="fa-light fa-eye"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='already-have-account'>
                        By creating an account you agree to the <a href='https://ilmiya.com/legal/terms-of-service'>Terms of Service</a> and <a href='https://ilmiya.com/legal/privacy-policy'>Privacy Policy</a>.
                    </div>
                    <button className='btn btn-primary'>
                        Create ilmiya account
                    </button>
                    <div className='already-have-account'>
                        Already have an ilmiya account? <a href=''> Login <i className="fa-regular fa-arrow-right"></i></a>
                    </div>
                    <div className='help-center mt-3'>
                        <a href='https://docs.ilmiya.com'>Help</a>
                        <span>|</span>
                        <a href=''>Privacy</a>
                        <span>|</span>
                        <a href=''>Terms</a>
                    </div>
                </div>
            </div>
            {/* create account form */}

            {/* Verify Email */}
            <div className='accounts-body'>
                <img className='accounts-logo' src={require('../images/logo/logo.png')} />
                <div className='create-account-card'>
                    <h1 className='mb-0'>Verify Email</h1>
                    <div className='terms-privacy'>
                        We have sent out a confirmation code to bahaji@ilmiya.com
                    </div>
                    <div className='form-card'>
                        <div className='form-code'>
                            <input type="text" className='form-control' />
                            <input type="text" className='form-control' />
                            <input type="text" className='form-control' />
                            <input type="text" className='form-control' />
                            <input type="text" className='form-control' />
                            <input type="text" className='form-control' />
                        </div>
                    </div>
                    <button className='btn btn-primary'>
                        Confirm
                    </button>
                    <div className='already-have-account'>
                        Didn't receive code?<a href=''> Resend code <i className="fa-regular fa-arrow-right"></i></a>
                    </div>
                    <div className='help-center mt-5'>
                        <a href='https://docs.ilmiya.com'>Help</a>
                        <span>|</span>
                        <a href='https://ilmiya.com/legal/privacy-policy'>Privacy</a>
                        <span>|</span>
                        <a href='https://ilmiya.com/legal/terms-of-service'>Terms</a>
                    </div>
                </div>
            </div>
            {/* Verify Email */}

            {/* login */}
            <div className='accounts-body'>
                <img className='accounts-logo' src={require('../images/logo/logo.png')} />
                <div className='create-account-card'>
                    <h1>Log In</h1>
                    <div className='form-card'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='input-group'>
                                    <input type="text" placeholder='Username or Email' className='form-control' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className='btn btn-primary'>
                        Next
                    </button>
                    <div className='devider'>
                        <div className='linee'></div>
                        <span>Or continue with</span>
                    </div>
                    <div className='other-options'>
                        <div className='row'>
                            <div className='col-4'>
                                <div className='option-btn'>
                                    <img src={require('../images/logo/Google.png')} />
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='option-btn'>
                                    <img src={require('../images/logo/apple.png')} />
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='option-btn'>
                                    <img src={require('../images/logo/Microsoft.png')} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='already-have-account'>
                        New to ilmiya?   <a href=''> Get started <i className="fa-regular fa-arrow-right"></i></a>
                    </div>
                    <div className='help-center'>
                        <a href='https://docs.ilmiya.com'>Help</a>
                        <span>|</span>
                        <a href=''>Privacy</a>
                        <span>|</span>
                        <a href=''>Terms</a>
                    </div>
                </div>
            </div>
            {/* login */}

            {/* Welcome Back! */}
            <div className='accounts-body'>
                <img className='accounts-logo' src={require('../images/logo/logo.png')} />
                <div className='create-account-card'>
                    <h1>Welcome Back!</h1>
                    <div className='form-card'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='user-card'>
                                    <div className='profile'>
                                        <img className='user' src={require('../images/AVATARS/Flare.png')} />
                                        <span>
                                            Taha Bhaji
                                        </span>
                                    </div>
                                    <div className='edit'>
                                        <a href=''><i className="fa-regular fa-pen"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className='input-group'>
                                    <input type="password" placeholder='Password' className='form-control' />
                                    <i className="fa-light fa-eye"></i>
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className='forgot-pass'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label className="form-check-label" for="flexCheckDefault">
                                            Show password
                                        </label>
                                    </div>
                                    <div><a href=''>Forgot password?</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className='btn btn-primary'>
                        Next
                    </button>

                    <div className='help-center mt-5'>
                        <a href='https://docs.ilmiya.com'>Help</a>
                        <span>|</span>
                        <a href='https://ilmiya.com/legal/privacy-policy'>Privacy</a>
                        <span>|</span>
                        <a href='https://ilmiya.com/legal/terms-of-service'>Terms</a>
                    </div>
                </div>
            </div>
            {/* Welcome Back! */}

            {/* Wrong password */}
            <div className='accounts-body'>
                <img className='accounts-logo' src={require('../images/logo/logo.png')} />
                <div className='create-account-card'>
                    <h1>Welcome Back!</h1>
                    <div className='form-card'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='user-card'>
                                    <div className='profile'>
                                        <img className='user' src={require('../images/AVATARS/Flare.png')} />
                                        <span>
                                            Taha Bhaji
                                        </span>
                                    </div>
                                    <div className='edit'>
                                        <a href=''><i className="fa-regular fa-pen"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className='input-group wrong-pass'>
                                    <input type="password" placeholder='Password' className='form-control' />
                                    <i className="fa-light fa-eye"></i>
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className='forgot-pass'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label className="form-check-label" for="flexCheckDefault">
                                            Show password
                                        </label>
                                    </div>
                                    <div><a href=''>Forgot password?</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className='btn btn-primary'>
                        Next
                    </button>

                    <div className='help-center mt-5'>
                        <a href='https://docs.ilmiya.com'>Help</a>
                        <span>|</span>
                        <a href=''>Privacy</a>
                        <span>|</span>
                        <a href=''>Terms</a>
                    </div>

                    <div className='wrong-pass-card'>
                        <i className="fa-solid fa-circle-xmark"></i>
                        <span>
                            Wrong password. Try again or click Forgot password.
                        </span>
                    </div>
                </div>
            </div>
            {/* Wrong password */}

            {/* Forget your password? */}
            <div className='accounts-body'>
                <img className='accounts-logo' src={require('../images/logo/logo.png')} />
                <div className='create-account-card'>
                    <h1 className='mb-0'>Forget your password?</h1>
                    <div className='terms-privacy mb-4'>
                        We'll email instructions to <br /> bahaji@ilmiya.com on how to reset it.
                    </div>
                    <div className='form-card'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='user-card'>
                                    <div className='profile'>
                                        <img className='user' src={require('../images/AVATARS/Flare.png')} />
                                        <span>
                                            Taha Bhaji
                                        </span>
                                    </div>
                                    <div className='edit'>
                                        <a href=''><i className="fa-regular fa-pen"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className='btn btn-primary mt-4'>
                        Reset password
                    </button>

                    <div className='help-center mt-5'>
                        <a href='https://docs.ilmiya.com'>Help</a>
                        <span>|</span>
                        <a href=''>Privacy</a>
                        <span>|</span>
                        <a href=''>Terms</a>
                    </div>

                </div>
            </div>
            {/* Forget your password? */}

            {/* Forget your password? */}
            <div className='accounts-body'>
                <img className='accounts-logo' src={require('../images/logo/logo.png')} />
                <div className='create-account-card'>
                    <h1 className='mb-0'>Forget your password?</h1>
                    <div className='terms-privacy mb-4'>
                        We'll email instructions to <br /> bahaji@ilmiya.com on how to reset it.
                    </div>
                    <div className='form-card'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='user-card'>
                                    <div className='profile'>
                                        <img className='user' src={require('../images/AVATARS/Flare.png')} />
                                        <span>
                                            Taha Bhaji
                                        </span>
                                    </div>
                                    <div className='edit'>
                                        <a href=''><i className="fa-regular fa-pen"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className='btn btn-primary mt-4'>
                        Reset password
                    </button>

                    <div className='help-center mt-5'>
                        <a href='https://docs.ilmiya.com'>Help</a>
                        <span>|</span>
                        <a href=''>Privacy</a>
                        <span>|</span>
                        <a href=''>Terms</a>
                    </div>
                    <div className='right-pass-card'>
                        <i className="fa-solid fa-circle-check"></i>
                        <span>
                            A link to reset your password has been emailed to you.
                        </span>
                    </div>
                </div>
            </div>
            {/* Forget your password? */}

            {/* Create  Organization */}
            <div className='organization-body'>
                <div className="auth-header">
                    <div className="container">
                        <div className="header-row">
                            <img className="logo" src={require("../images/logo.png")} />
                            <button className="logout btn btn-danger" id="logoutButton">
                                <i className="fa-regular fa-arrow-right-from-bracket"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div className='create-organization-card'>
                    <h1 className='mb-4'>Create  Organization Name</h1>
                    <div className='input-group'>
                        <input type="text" placeholder='Organization Name' className='form-control' />
                    </div>
                    <div className='action-btns'>
                        <div className='row'>
                            <div className='col-6'>
                                <button className='btn btn-light'>Cancel</button>
                            </div>
                            <div className='col-6'>
                                <button className='btn btn-primary'>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Create  Organization */}

            {/* Create  Organization */}
            <div className='organization-body'>
                <img className='organization-logo' src={require('../images/logo/logo.png')} />
                <div className='create-organization-card'>
                    <h1>Choose Custom Domain</h1>
                    <h6>Please note, workspace names can't be changed.</h6>
                    <InputGroup className="input-group-text-box">
                        <Form.Control placeholder="Domain Name" />
                        <InputGroup.Text id="basic-addon2">.myilmiya.com</InputGroup.Text>
                    </InputGroup>
                    <div className='action-btns'>
                        <div className='row'>
                            <div className='col-6'>
                                <button className='btn btn-light'>Cancel</button>
                            </div>
                            <div className='col-6'>
                                <button className='btn btn-primary'>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tab-screen'>
                    <div className='dots'>
                        <div className='red'></div>
                        <div className='yellow'></div>
                        <div className='green'></div>
                    </div>
                    <div className='form'>
                        <input type="text" readOnly placeholder='bahaji.myilmiya.com' className='form-control' />
                    </div>
                    <div className='dots'></div>
                </div>
            </div>
            {/* Create  Organization */}

        </div>
    )
}
