const localhost = process.env.REACT_APP_NODE_ENV === 'localhost'
const development = process.env.REACT_APP_NODE_ENV === 'development'
const staging = process.env.REACT_APP_NODE_ENV === 'staging'
export const app_mode = localhost ? 'localhost' : development ? "development" : staging ? "staging" : "production"
export const domain = localhost ? 'local' : development ? 'dev' : staging ? 'app' : 'com'
console.log("REACT_APP_NODE_ENV", process.env.REACT_APP_NODE_ENV)
export const basePath = process.env.REACT_APP_basePath ?? (development ? "http://192.168.3.116:6060/iam/api/" : staging ? "https://api.ilmiya.dev/iam/api/" : "https://api.ilmiya.com/iam/api/");
export const userBasePath = process.env.REACT_APP_userBasePath ?? (development ? "http://192.168.3.116:6060/users/api/" : staging ? "https://api.ilmiya.dev/users/api/" : "https://api.ilmiya.com/users/api/");
export const contentPath = process.env.REACT_APP_contentBasePath ?? (development ? "http://192.168.3.116:6063/content/api" : staging ? "https://api.ilmiya.dev/content/api/" : "https://api.ilmiya.com/content/api/");
console.log("REACT_APP_RESEND", process.env.REACT_APP_RESEND)
export const APIurls = {
  // auth
  createUser: userBasePath + "/auth/signup",
  forgetPassword: userBasePath + "/auth/forget-password",
  resetPassword: userBasePath + "/auth/reset-password",
  getUserWithEmail: userBasePath + "profile/getuser",
  // authors
  authorsMembership: "authors/membership",
  authors: "authors",
  // org
  organizations: "organizations",
  bymail: "organizations/by-mail",
  membership: "organizations/membership",
  profile: userBasePath + "profile",
  importScripts: userBasePath + "importScript/create-new-orgs",
  auth: "auth",
  // content
  contents: contentPath + "contents",
  userContents: contentPath + "user/contents",
  invites: userBasePath + "invites",
  invitesSuccess: userBasePath + "invites/success",
  authorInvitesSuccess:  "authors/invites/success",

};

export const space = window.location.hostname.split('.')[0];
// export const redirectToConsole = (space) => localhost ? `http://localhost:3001/console/${space}/` : development ? `https://platform.ilmiya.dev/console/${space}/` : staging ? `https://platform.ilmiya.app/console/${space}/` : `https://platform.ilmiya.com/${space}/`;
export const redirectToConsole = (space) => localhost ? `http://localhost:3015/console/${space}/` : development ? `https://platform.ilmiya.dev/console/${space}/` : staging ? `https://platform.ilmiya.app/console/${space}/` : `https://platform.ilmiya.com/console/${space}/`;
export const redirectToAdmin = (space) => localhost ? `http://localhost:3005/admin/${space}/` : development ? `https://platform.ilmiya.dev/admin/${space}/` : staging ? `https://platform.ilmiya.app/admin/${space}/` : `https://platform.ilmiya.com/admin/${space}/`;
export const redirectToAuthor = (space) => localhost ? `http://localhost:3006/studio/${space}/` : development ? `https://studio.ilmiya.dev/studio/${space}/` : staging ? `https://studio.ilmiya.app/studio/${space}/` : `https://studio.ilmiya.com/studio/${space}/`;
export const redirectToLogin = (space) => localhost
  ? `http://${space}.us.localhost:3001/`
  : development
    ? `https://${space}.myilmiya.dev/`
    : staging
      ? `https://${space}.myilmiya.app/`
      : `https://${space}.myilmiya.com/`;
export const redirect_uri = localhost ? "http://localhost:3000/signup/" : development ? "https://accounts.ilmiya.dev/signup/" : staging ? "https://accounts.ilmiya.app/signup/" : "https://accounts.ilmiya.com/signup/";