import { useEffect, useState } from "react";
import { Button, Card, Form, InputGroup, Row } from "react-bootstrap";
import { domain, redirectToAdmin, redirectToConsole } from "../constants";
import { ApiRequests } from "../service/ApiRequests";
import { parseUrlFromModel } from "../utils/asset";
import { auth } from "../config/firebase";
import { getAuth } from "firebase/auth";
import Logout from "./logout";
import { Link, useNavigate } from "react-router-dom";
import { countUserEvent } from "../utils/utlis";

const CreateOrganization = ({ setActiveStep }) => {
  const _auth = getAuth();
  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState("");
  console.log("error:::::", error);
  const [progress, setProgress] = useState(0);
  const [token, setToken] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [step, setStep] = useState(0);
  const [orgs, setOrgs] = useState([]);
  const [message, setMessage] = useState([]);
  const [loading, setLoading] = useState();
  const [uploadedContent, setUploadedContent] = useState();
  const [page1, setPage1] = useState(1);
  const [limit1, setLimit1] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const range = 5;
  // const data = getOrganizationsMembership((s)=>s?.data?.data) ?? {};
  const { page, limit, results, totalPages, totalResults, users } = orgs;
  const [organization, setOrganization] = useState({
    name: "",
    displayName: "",
    // enabled_connections: ["Username-Password-Authentication"],
    branding: {
      logo_url:
        "https://content.ilmiyacdn.com/public/1708069689872-ilmiya_logo.png",
      colors: { primary: "#0070f3", page_background: "#222" },
    },
    // branding: {
    //   logo_url: "",
    //   colors: { primary: "#0070f3", page_background: "#222" },
    // },
    metadata: { company_email: "" },
  });

  const [formattedName, setFormattedName] = useState("");
  

  const getOrganizationsMembership = async () => {
    try {
      const params = {
        page: currentPage,
        limit: limit1,
      };
      const { data } = await ApiRequests.getOrganizationsMembership(params);
      console.log("data????????????????????:", data);
      setOrgs(data);
    } catch (error) {
      console.log("error:getOrganizationsMembership:", error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    setValidated(true);

    if (step === 1) {
      createOrganization();
    } else {
      setStep(step + 1);
    }
  };

  const createOrganization = async () => {
    setLoading(true);
    try {
      let content;
      // upload assets
      if (selectedImage && !uploadedContent) {
        const formData = new FormData();
        formData.append("files", selectedImage);
        content = await ApiRequests.createContent({
          data: formData,
          options: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              console.log("percentCompleted::", percentCompleted);
              setProgress(percentCompleted);
            },
            onDownloadProgress: (progressEvent) => {
              const progress =
                50 + (progressEvent.loaded / progressEvent.total) * 50;
              console.log("progress:onDownloadProgress::", progress);
              setProgress(progress);
            },
          },
        });
      }
      if (
        content?.data &&
        Array.isArray(content?.data) &&
        content?.data?.length
      ) {
        content = parseUrlFromModel(content.data[0]);
        setUploadedContent(parseUrlFromModel(content.data[0]));
      }
      if (uploadedContent) {
        content = uploadedContent;
      }

      // crfeate org
      const { data } = await ApiRequests.createOrganization({
        ...organization,
        branding: {
          ...organization.branding,
          ...(typeof content === "string" ? { logo_url: content } : {}),
        },
        // enabled_connections: connections.map(e => { return { connection_id: e.id, assign_membership_on_login: true } })
      });
      if (data) {
        const token = await fetchToken();
        // window.location = `http://${data.organization.name}.us.localhost:3001`
        window.location = `${redirectToConsole(
          data.organization.displayName
        )}?token=${token}`;
      }
      setLoading(false);
    } catch (error) {
      console.log("error:", error);
      setError(error?.response?.data);
      setLoading(false);
      setMessage(
        JSON.stringify(
          error?.response?.data?.message ??
            error?.message ??
            "Something went wrong!",
          null,
          2
        )
      );
    }
  };
  useEffect(() => {
    getOrganizationsMembership();
  }, [currentPage, limit]);
  const fetchToken = async () => {
    try {
      console.log("auth.currentUser", auth.currentUser);
      console.log("auth.currentUser", _auth.currentUser.accessToken);
      const { data } = await ApiRequests.getCustomToken({
        uid: auth.currentUser.uid,
      });
      console.log("data:::", data);
      setToken(data.customToken);
      return data.customToken;
    } catch (error) {
      console.error("Error fetching token:", error);
    }
  };
  console.log("user", auth.currentUser);
  useEffect(() => {
    fetchToken();
  }, []);

  const handleNextPage = () => {
    if (currentPage < totalPages && currentPage > 0) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleClickPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    const pages = [];
    const startPage = Math.max(1, currentPage - Math.floor(range / 2));
    const endPage = Math.min(totalPages, startPage + range - 1);
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => {
            handleClickPage(i);
          }}
          className={currentPage + 1 === i ? "active" : ""}
        >
          {i}
        </button>
      );
    }
    if (totalPages > 5 && startPage > 1) {
      pages.unshift(<span>...</span>);
    }
    if (totalPages > 5 && endPage < totalPages) {
      pages.push(<span>...</span>);
    }
    return pages;
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {step === 0 && (
          <div className="organization-body">
            <Link to="/">
              <img
                className="organization-logo"
                src={require("../images/logo/logo.png")}
                alt="logo"
              />
            </Link>
            <Logout />
            <div className="create-organization-card">
              <h1 className="mb-4">Create Organization Name</h1>
              <div className="input-group">
                <Form.Control
                  style={error?.message ? { color: "#EF4444" } : null}
                  name="displayName"
                  required
                  value={organization.displayName}
                  onChange={(e) => {
                    
                    const displayName = e.target.value;
                    const formattedDisplayName = displayName.replace(
                      /[^\w\s]/gi,
                      ""
                    );
                    setOrganization({
                      ...organization,
                      displayName: formattedDisplayName,
                    });
                    setError(null);
                  }}
                  placeholder="Organization Name"
                  type="text"
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter display name!
                </Form.Control.Feedback>
              </div>
              <div className="action-btns">
                <div className="row">
                  <div className="col-6">
                    <Link to="/find" className="btn btn-light">
                      Cancel
                    </Link>
                  </div>
                  <div className="col-6">
                    <button onClick={()=>countUserEvent("create_organization" , {eventName : "create_organization"})} className="btn btn-primary">Next</button>
                  </div>
                </div>
              </div>
            </div>
            {orgs && orgs.results && orgs.results.length > 0 && (
              <div className="organizations mt-5">
                {/* <Form.Label className="h4">
                  Your Organizations
                  <span className="des">
                    (Click to access login portal for your organizations.)
                  </span>
                </Form.Label> */}
                {/* <Row className="px-2">
                  {orgs.results.map((org, i) => (
                    <div key={i} className="col-md-6">
                      <a
                        href={`${redirectToConsole(org?.name)}?token=${token}`}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <div className="capsol">
                          <Card.Img
                            variant="top"
                            src={org?.branding?.logo_url}
                          />
                          <Card.Body>
                            <Card.Title>{org?.name}</Card.Title>
                            <Card.Text>{org?.displayName}</Card.Text>
                          </Card.Body>
                        </div>
                      </a>
                    </div>
                  ))}
                  {totalPages > 1 && (
                    <div className="SetData-Btn">
                      <button onClick={handlePreviousPage}>Previous</button>
                      {renderPagination()}
                      <button onClick={handleNextPage}>next</button>
                    </div>
                  )}
                </Row> */}

                {orgs?.results?.length > 0 && (
                  <Link to="/organizations">
                    <span style={{ color: "black" }} className="btn btn-light">
                      My organizations
                    </span>
                  </Link>
                )}

                {/* <Button
                  disabled={disabled}
                  onClick={async () => {
                    const resp = await ApiRequests.importScriptTriger();
                    console.log("resp From import triger:", resp.data);
                    setDisabled(true);
                  }}
                >
                  Import
                </Button> */}
              </div>
              //   ) : (
              //     <p>No organizations found.</p>
            )}
          </div>
        )}
        {step === 1 && (
          <div className="organization-body">
            <Link to="/">
              <img
                className="organization-logo"
                src={require("../images/logo/logo.png")}
                alt="logo"
              />
            </Link>
            <Logout />
            <div className="create-organization-card">
              <h1>Choose Custom Domain</h1>
              <h6>Please note, workspace names can't be changed.</h6>
              <InputGroup className="input-group-text-box">
                {/* <Form.Control placeholder="Domain Name" /> */}
                <Form.Control
                  style={error?.message ? { color: "#EF4444" } : null}
                  name="name"
                  required
                  value={formattedName}
                  onChange={(e) => {
                    const newName = e.target.value.toLowerCase();
                    const formatted = newName.replace(/[^a-zA-Z0-9_]/g, "");
                    setFormattedName(formatted);
                    setOrganization({
                      ...organization,
                      name: formatted,
                    });
                    setError(null);
                  }}
                  pattern="[A-Za-z0-9_]+"
                  placeholder="acme"
                  type="text"
                  onKeyPress={(e) => {
                    if (
                      e.key.match(/[^a-zA-Z0-9_]/) ||
                      (formattedName.length === 1 && e.key === "Backspace")
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
                <InputGroup.Text id="basic-addon2">
                  .myilmiya.{domain}
                </InputGroup.Text>
              </InputGroup>
              {error?.message && (
                <div className="alert alert-danger mt-3" role="alert">
                  {error?.message === "displayName already taken"
                    ? "This workspace name already exits. Please select another name."
                    : error?.message ===
                      "Display name should start with a letter and only consist of letters, digits, and hyphens with 4-20 characters."
                    ? "Display name should start with a letter and only consist of letters, digits, and hyphens with 4-20 characters."
                    : error?.message}
                </div>
              )}
              <Form.Control.Feedback type="invalid">
                Please Enter Valid organization name!
              </Form.Control.Feedback>
              <div className="action-btns">
                <div className="row">
                  <div className="col-6">
                    <button
                      onClick={() => {
                        setStep(0);
                      }}
                      className="btn btn-light"
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-6">
                    <button disabled={loading} className="btn btn-primary">
                      {loading ? "Preparing..." : "Next"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-screen">
              <div className="dots">
                <div className="red"></div>
                <div className="yellow"></div>
                <div className="green"></div>
              </div>
              <div className="form">
                <input
                  type="text"
                  readOnly
                  disabled
                  placeholder={`${formattedName ?? "abc"}.myilmiya.${domain}`}
                  className="form-control"
                />
              </div>
              <div className="dots"></div>
            </div>
          </div>
        )}
      </Form>
    </>
  );
};
export default CreateOrganization;
